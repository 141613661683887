import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExponentialBackoffService } from './exponential-backoff.service'
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentUploadService {
    constructor(
        private readonly http: HttpClient,
        private readonly userService: UserService,
        private readonly exponentialBackoffService: ExponentialBackoffService) {

    }

    getTempDocUrl(fileName: string): Observable<{filename: string, presignedURL: string}> {
        const url = `${environment.afcBaseUrl}/document/temp/`;
        const headers: any = {
            Authorization: this.userService.getAccessToken(),
        };
        return this.http.post<object>(url, { filename: fileName }, {
            headers
        }).pipe(
            this.exponentialBackoffService.exponentialBackoffRetry(3, 1000)
        );
    }

    putDocumentToPresignedUrl(url: string, fileType: string, imageDataURI: string): Observable<object> {
        const data = this.dataURItoBlob(imageDataURI);
        const headers: any = {};
        if (fileType) {
            headers['Content-Type'] = `image/${fileType}`;
        }
        return this.http.put<object>(url, data, {
            headers
        }).pipe(
            this.exponentialBackoffService.exponentialBackoffRetry(3, 1000)
        );
    }

    // We need atob to  decode the b64 string so that it will be uploaded to s3 appropriately.
    // just sending the b64 string will not work and you will not have an image that can be worked with
    dataURItoBlob(dataURI: string) {
        // convert base64/URLEncoded data component to raw binary data held in a string
        const binary = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        return new Blob([new Uint8Array(array)], { type: mimeString });
    }

}