import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {

  canUpdateManifest$!: Observable<boolean>;
  canUpdateHeader$!: Observable<boolean>;
  canReadReport$!: Observable<boolean>;
  canDoBulkUpdate$!: Observable<boolean>;


  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth, private readonly router: Router,
    public userService: UserService) { }

  ngOnInit() {
    this.canUpdateManifest$ = this.userService.ready$.pipe(
      map(ready => {
        let canUpdateManifest = false;
        if(ready) {
          canUpdateManifest = this.userService.hasTopLevelPermission('read-manifest');
        }
        return canUpdateManifest;
      })
    )

    this.canUpdateHeader$ = this.userService.ready$.pipe(
      map(ready => {
        let canUpdateHeader = false;
        if(ready) {
          canUpdateHeader = this.userService.hasTopLevelPermission('read-header');
        }
        return canUpdateHeader;
      })
    )

    this.canReadReport();
    this.canDoBulkUpdate();
  }

  canReadReport(){
    this.canReadReport$ = this.userService.ready$.pipe(
      map(ready => {
        let canReadReport = false;
        if(ready) {
          canReadReport = this.userService.hasTopLevelPermission('read-reports');
        }
        return canReadReport;
      })
    )
  }

  canDoBulkUpdate(){
    this.canDoBulkUpdate$ = this.userService.ready$.pipe(
      map(ready => {
        let canDoBulkUpdate = false;
        if(ready) {
          canDoBulkUpdate = this.userService.hasTopLevelPermission('bulk-update-user');
        }
        return canDoBulkUpdate;
      })
    )
  }

  async logout(){
    await this.oktaAuth.signOut({postLogoutRedirectUri: `${window.location.origin}/logged-out/`});
  }
}
