import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { delay, retryWhen, scan } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})

export class ExponentialBackoffService {
  constructor() { }
/**
 * This function can be used with observables to retry it in case of failure.
 * @Param maxRetries: number of retries to be done.
 * @Param delayMs : accepts delay in milliseconds for each retry.
 * This function uses rxjs retryWhen,dealy functions. in new version (rxjs 7+) it allows changing delay after each retry to implement exponential backoff.
*/
  exponentialBackoffRetry(maxRetries: number, delayMs: number) { 
    return (
      source: Observable<any>) => source.pipe(
        retryWhen(errors => errors.pipe( // `scan` to track retry count
          scan((retryCount, error) => { 
            // Stop retrying after reaching maxRetries, retryCount starts from 0.
            if (retryCount >= maxRetries) { throw error; } 
            return retryCount + 1; }, 0), // Apply exponential backoff delay
          delay(delayMs) 
        )
      )
    );
  };
}


