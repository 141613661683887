export class AppConstants {

    public static get US(): string { return 'US'; }
    public static get Canada(): string { return 'CA'; }
    public static get Message(): string { return 'message'; }

    public static get Http_Status_OK(): string { return '200'; }
    public static get Data(): string { return 'data'; }
    public static get Num_Min_Char_Code(): string { return '48'; }
    public static get Num_Max_Char_Code(): string { return '57'; }
    public static get DealerBankAccounts(): string { return 'dealerBankAccountsDOList'; }
    public static get keyDefault(): string { return 'defaultAccount'; }
    public static get key(): string { return 'bankAccountID'; }
    
    public static get defaultPlannedOutageMessage(): string { return 'The system will be down for planned maintenance.'; }
    public static get defaultUnplannedOutageMessage(): string { return 'Sorry to hit the brakes on you. Our team is working hard to get everything back up and running. Please check back soon or contact your local branch representative for immediate service.'; }

    public static toastrConfig = {
        closeButton: true,
        positionClass: 'toast-top-right'
    }

    public static get afcdPermissions() {
        return [
            {
                value: "account-signer",
                label: "Contract Signer",
                setByDefaultOnNewDealership: false,
            },
            {
                value: "read-credit-limit",
                label: "View Credit Availability",
                parent: ["read-title-release", "submit-payments"],
                setByDefaultOnNewDealership: true,
                readonly: true
            },
            {
                value: "read-charges",
                label: "View Floorplan Charges",
                parent: ["read-title-release"],
                setByDefaultOnNewDealership: true,
                readonly: true
            },
            {
                value: "read-payments",
                label: "View Past Payment Activity",
                parent: ["submit-payments"],
                setByDefaultOnNewDealership: true,
                readonly: true
            },
            {
                value: "submit-nap",
                label: "Submit NAP (Floor a Unit)",
                setByDefaultOnNewDealership: true,
                readonly: true
            },
            {
                value: "submit-payments",
                label: "Submit Payment",
                setByDefaultOnNewDealership: true
            },
            {
                value: "read-title-release",
                label: "View Title Release",
                parent: ["submit-title-release"],
                setByDefaultOnNewDealership: true,
                readonly: true
            },
            {
                value: "submit-title-release",
                label: "Submit Title Release",
                setByDefaultOnNewDealership: true
            },
            {
                value: "read-verification",
                label: "View Vehicle Verification",
                parent: ["submit-verification"],
                setByDefaultOnNewDealership: true,
                readonly: true
            },
            {
                value: "submit-verification",
                label: "Submit Vehicle Verification",
                setByDefaultOnNewDealership: true

            },
            {
                value: "read-reports",
                label: "View Reports",
                setByDefaultOnNewDealership: true,
                readonly: true
            },
            {
                value: "read-document",
                label: "View Documents",
                setByDefaultOnNewDealership: true,
                readonly: true
            },
            {
                value: "read-bank-accounts",
                label: "View Bank Accounts",
                parent: ["submit-payments","update-bank-account", "create-bank-account","delete-bank-account"],
                setByDefaultOnNewDealership: true,
                readonly: true
            },
            {
                value: "update-bank-account",
                label: "Update Bank Accounts",
                setByDefaultOnNewDealership: true
            },
            {
                value: "create-bank-account",
                label: "Create Bank Accounts",
                setByDefaultOnNewDealership: true
            },
            {
                value: "delete-bank-account",
                label: "Delete Bank Accounts",
                setByDefaultOnNewDealership: true
            },
            {
                value: "read-floorplan",
                label: "Read Floorplan",
                setByDefaultOnNewDealership: true
            },
            {
                value: "create-shipping-label",
                label: "Create Shipping Label",
                setByDefaultOnNewDealership: true
            }
          ]
    };

    // Used to generate top level permissions when creating internal users
    public static get afcdTopLevelPermissions() {
        return [
            {
                value: "create-temp-document",
                label: "Upload documents"
            },
            {
                value: "read-agreements",
                label: "Check Status of Agreement Acceptance",
            },
            {
                value: "search-user",
                label: "Search Users",
            }
            ,
            {
                value: "read-alerts",
                label: "Read User Alerts",
            }
          ]
    };

}    