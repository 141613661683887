<ion-content class="ion-padding">
    <ngx-spinner type="ball-beat" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff">
        <p style="color: white">Loading...</p>
    </ngx-spinner>
    <p><a [routerLink]="'/home'">Home</a></p>
    <div class="neo-content">
        <h1>Bulk update user permissions</h1>
        <div class="flex-row-even form-content-wrapper">
            <form class="box bulkupdate-form" (ngSubmit)="submitBulkUpdateRequest()" [formGroup]="userForm"
                 autocomplete=off>
                <div class="table-options-wrapper" *ngIf="applications.length">
                    <mat-form-field>
                        <mat-label>Choose an application</mat-label>
                        <mat-select formControlName="appId"
                            (selectionChange)="getApplicationRoles()">
                            <mat-option *ngFor="let application of applications" [value]="application.value">
                                {{ application.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error class="error" *ngIf="userForm?.get('appId')?.errors?.['required'] && userForm?.get('appId')?.touched">
                            Application id is required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="table-options-wrapper">
                    <mat-form-field>
                        <mat-label>Choose an role to update</mat-label>
                        <mat-select formControlName="permission"
                            (selectionChange)="toggleUploadField()">
                            <mat-option *ngFor="let applicationRole of applicationRoles"
                                [value]="applicationRole.value">
                                {{ applicationRole.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error class="error" *ngIf="userForm?.get('permission')?.errors?.['required']">
                            Role for application is required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="table-options-wrapper">
                    <mat-form-field>
                        <mat-label>Choose an operation</mat-label>
                        <mat-select formControlName="operation">
                            <mat-option [value]="'add'">add</mat-option>
                            <mat-option [value]="'remove'">remove</mat-option>
                        </mat-select>
                        <mat-error class="error" *ngIf="userForm?.get('operation')?.errors?.['required'] && userForm?.get('operation')?.touched">
                            Operation is required.
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="custom-checkbox" *ngIf="!isAccountSigner">
                    <input formControlName="updateAll" (change)="toggleDealeList()" type="checkbox" name="update all" id="updateall-checkbox">
                    <label for="updateall-checkbox">update all</label>
                </div>
                <div *ngIf="!isAccountSigner && isDealerList">
                    <label for="dealerList">Dealer Id Lists(',' separated)</label>
                    <input formControlName="dealerList" type="text" id="dealerList">
                    <mat-error class="error" *ngIf="userForm?.get('dealerList')?.errors?.['required'] && userForm?.get('dealerList')?.touched">
                        Dealer id list is required.
                    </mat-error>
                    <mat-error class="error" *ngIf="userForm?.get('dealerList')?.errors?.['pattern'] && userForm?.get('dealerList')?.dirty">
                        invalid details. please use numbers, *, and , to separate multiple dealers.
                    </mat-error>
                </div>
                <div class="upload-field" *ngIf="isAccountSigner">
                    <label for="uploadFile">Upload CSV file</label>
                    <input id="uploadFile" type="file" formControlName="documentId" accept=".csv" (change)="uploadCSV_File($event)">
                    <mat-error id="uploadFieldError" class="error" *ngIf="userForm?.get('documentId')?.errors?.['required'] && userForm?.get('documentId')?.touched">Please Upload CSV file</mat-error>
                </div>

                <button mat-raised-button color="primary" type="submit" id="saveUserButton" class="btn"
                    [ngClass]="{'secondary inactive': userForm.invalid}"
                    [disabled]="!userForm.valid">Submit</button>
            </form>
            <div *ngIf="Requests.length>0" class="bulkupdate-form box verticalScroll">
                <div *ngFor="let request of Requests" class="box">
                    <div>ID: {{request.id}}</div>
                    <div>Message: {{request.message}}</div>
                </div>
            </div>
        </div>
    </div>
</ion-content>