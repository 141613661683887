import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { getProperty } from 'dot-prop';
import { jwtDecode } from 'jwt-decode'
import queryString from 'query-string';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AfcUser, Context } from 'src/app/models/http/afc-user';
import { environment } from 'src/environments/environment';
import { IAlertResp } from '../user/alert.interface';




@Injectable({
  providedIn: 'root'
})
export class UserService {

  public readonly readySubject = new BehaviorSubject(false);
  ready$ = this.readySubject.asObservable();

  public readonly topLevelPermissionsSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public readonly applicationContextsSubject: BehaviorSubject<any> = new BehaviorSubject([]);
  public readonly afcUserSubject: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private http: HttpClient,
    private router: Router) {
      this.loadUserData();
  }

  async loadUserData() {
    let isAuthenticated = await this.isAuthenticated();
    if (isAuthenticated) {
      let accessToken = this.getAccessToken();
      const decodedToken:any = accessToken ? jwtDecode(accessToken): null;
      const emailAddress = decodedToken.sub;
      // If information is not passed in token, call UserAuth to load info.
      if (!decodedToken.afc_user_id || !decodedToken.application_context) {
        console.log('afc_user_id or application_context not found in token. Loading from API.');
        this.getLoggedinUserInfo(emailAddress, accessToken).subscribe({
          next: users => {
            const afcUser = users[0];
            this.afcUserSubject.next(afcUser);
            let applicationContext = getProperty(afcUser, 'applicationContextRole.super-admin');
             this.topLevelPermissionsSubject.next(getProperty(applicationContext, 'roles')?getProperty(applicationContext, 'roles'):[]);
            this.applicationContextsSubject.next(getProperty(applicationContext, 'contexts'));
            this.readySubject.next(true);
          },
          error: err => {
            console.log('Error calling getLoggedinUserInfo()', err);
            this.router.navigate(['/error'], {
              queryParams: {
                message: `You don't have permission to access this App. Contact AFC administrator for access.`,
                context: JSON.stringify(err),
                errorType: 'USER_ERROR'
              }
            });
          },
          complete: () => {
            console.log('HTTP request, getLoggedinUserInfo() completed.')
          }
        });
      } else {
        // Load user information from token
        let applicationContext = JSON.parse(decodedToken.application_context);
        this.topLevelPermissionsSubject.next(applicationContext.roles?applicationContext.roles:[]);
        this.applicationContextsSubject.next(applicationContext.contexts);
        this.readySubject.next(true);
      }
    } else {
      this.signInWithRedirect('/home');
    }
  }

  private waitForReady = new Promise((resolve, reject) => {
    console.log('DEBUG', 'Waiting for user service to be ready....');
    if (this.readySubject.getValue()) {
      resolve(true);
    } else {
      this.ready$.subscribe(b => {
        if (b) {
          console.log('DEBUG', 'UserService waitForReady: resolving: ' + b);
          resolve(true);
        }
      });
    }
  });

  async canActivate() {
    await this.waitForReady;
    console.log('DEBUG', 'UserService canActivate passed.');
    return true;
  }

  public getApplications(): Observable<Object> {
    const url = `${environment.afcBaseUrl}/userauth/application`;
    const options: Object = {
      headers: {
        'Authorization': this.getAccessToken()
      }
    };
    return this.http.get<Object>(url, options);
  }

  public getApplicationRoles(appid: string): Observable<Object> {
    const url = `${environment.afcBaseUrl}/userauth/application/${appid}/roles`;
    const options: Object = {
      headers: {
        'Authorization': this.getAccessToken()
      }
    };
    return this.http.get<Object>(url, options);
  }

  public getLoggedinUserInfo(emailAddress: string, accessToken: string | undefined): Observable<AfcUser[]> {
    const url = `${environment.afcBaseUrl}/userauth/user?emailAddress=${emailAddress}`;
    const options: Object = {
      headers: {
        'Authorization': accessToken
      }
    };
    return this.http.get<AfcUser[]>(url, options);
  }

  public getUserById(userId: string): Observable<AfcUser> {
    const url = `${environment.afcBaseUrl}/userauth/user/${userId}`;
    const accessToken = this.getAccessToken();
    const params: Object = {
      headers: {
        'Authorization': accessToken
      }
    };
    return this.http.get<AfcUser>(url, params)
      .pipe(catchError(error => throwError(() => error)));
  }

  public getAccessToken() {
    return this.oktaAuth.getAccessToken();
  }

  async isAuthenticated() {
    return await this.oktaAuth.isAuthenticated();
  }

  public searchUsers(queryParams: any): Observable<AfcUser[]> {
    const url = `${environment.afcBaseUrl}/userauth/user?${queryString.stringify(queryParams)}`;
    const accessToken = this.getAccessToken();
    const params: Object = {
      headers: {
        'Authorization': accessToken
      }
    };
    return this.http.get<AfcUser[]>(url, params)
      .pipe(catchError(error => throwError(() => error)));
  }

  public createUser(user: AfcUser): Observable<any> {
    const url = `${environment.afcBaseUrl}/userauth/user`;
    const accessToken = this.getAccessToken();
    const options: Object = {
      headers: {
        'Authorization': accessToken
      }
    };
    return this.http.post<AfcUser>(url, user, options)
      .pipe(catchError(error => throwError(() => error)));
  }

  // This is a GET call to fetch all alerts of dealer
  getAlerts(userId: string, dealerList: string[]): Observable<IAlertResp[]>{
    // Create lit of urls with userid and dealerId
    const urlList = dealerList.map(dealerId => `${environment.afcBaseUrl}/notification/alert/${userId}/${dealerId}`);
    const accessToken = this.getAccessToken();
    const options: Object = {
      headers: {
        'Authorization': accessToken
      }
    };
    // Execute GET call simultiniously
    const observables = urlList.map(url => this.http.get<IAlertResp>(url, options));

    // Return signle ARRAY response to the component with all alerts
    return forkJoin(observables).pipe(
      map((responses: IAlertResp[]) => responses.flat()),
      catchError(error => of(error))
    );
  }

  // This is a PUT call to update all alerts of dealer
  deactivateAlerts(userId: string, alertsArr: IAlertResp[]){
    // Create array of objects containing "url" and its associated "alerts"
    const alertsWithUrlArr = alertsArr.map(alerts => {
      return {
        url: `${environment.afcBaseUrl}/notification/alert/${userId}/${alerts.alertPreference[0].dealerId}`,
        alerts
      }
    });
    const accessToken = this.getAccessToken();
    const options: Object = {
      headers: {
        'Authorization': accessToken
      }
    };
    // Execute PUT call simultiniously
    const observables = alertsWithUrlArr.map(obj => this.http.put<{"statusCode": number}>(obj.url, obj.alerts, options));
    
    // Return signle ARRAY response to the component with all results
    return forkJoin(observables).pipe(
      map((responses: {"statusCode": number}[]) => responses.flat()),
      catchError(error => of(error))
    );
  }

  public updateUser(user: AfcUser): Observable<any> {
    console.log(JSON.stringify(user));
    const url = `${environment.afcBaseUrl}/userauth/user/${user.id}`;
    const accessToken = this.getAccessToken();
    const options: Object = {
      headers: {
        'Authorization': accessToken
      }
    };
    return this.http.put<AfcUser>(url, user, options)
      .pipe(catchError(error => throwError(() => error)));
  }

  public hasTopLevelPermission(permission: string): boolean {
    const topLevelPermissions: string[] = this.topLevelPermissionsSubject.getValue();
    return topLevelPermissions.includes(permission);
  }

  /**
   * Pass Array of functions and it will return true if one of the permission exist for that user.
   * Not used at this point. May be needed for future
   * @param permissions 
   * @returns true or false
   */
  public hasTopLevelPermissions(permissions: string[]): boolean {
    const topLevelPermissions: string[] = this.topLevelPermissionsSubject.getValue();
    let validPermission = false;
    permissions.forEach(permission => {
      if (topLevelPermissions.includes(permission)) {
        validPermission = true;
      }
    });
    return validPermission;
  }

  public hasPermission(permission: string, contextType: string, contextId: string): boolean {
    let retVal = false;
    const applicationContexts = this.applicationContextsSubject.getValue();
    const matchedContext: any = applicationContexts.find((context: Context) =>
      context.contextType === contextType
      && (context.contextId === contextId || context.contextId === '*')
      && context.contextStatus === 'ACTIVE');
    if(matchedContext?.roles && Array.isArray(matchedContext.roles)){
      return matchedContext.roles.includes(permission);
    }
    return retVal;
  }

  public signInWithRedirect(route: string) {
    this.oktaAuth.signInWithRedirect({originalUri: route});
  }

  public getUsersByApplication(appName: string): Observable<AfcUser[]> {
    const url = `${environment.afcBaseUrl}/userauth/application/${appName}`;
    const accessToken = this.getAccessToken();
    const params: Object  = {
      headers: {
        'Authorization': accessToken
      }
    };
    return this.http.get<AfcUser[]>(url, params)
      .pipe(catchError(error => throwError(() => error)));
  }

  public submitBulkUpdatePermissionRequest(payload : any): Observable<any> {
    const url = `${environment.afcBaseUrl}/userauth/permissionsbulkupdate`;
    const accessToken = this.getAccessToken();
    const options: Object = {
      headers: {
        'Authorization': accessToken
      }
    };
    return this.http.post(url, payload, options)
      .pipe(catchError(error => throwError(() => error)));
  }

}
