import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { UserService } from '../services/user.service';
import { Observable, map } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { DocumentUploadService } from '../services/document.upload.service';

interface ApplicationDisplayName {
  'afcd': string;
  'auctionportal': string;
  'super-admin': string;
  appName?: string;
}

@Component({
  selector: 'app-bulkupdate-permissios',
  templateUrl: './bulkupdate-permissios.page.html',
  styleUrls: ['./bulkupdate-permissios.page.css']
})

export class BulkupdatePermissiosPage implements OnInit {
  canBulkUpdate$!: Observable<boolean | undefined>;
  applications: any = [];
  applicationRoles: {value: string, name: string}[] = [];
  updateAll: boolean = false;
  Requests: any[] = [];
  userForm: FormGroup;
  applicationDisplayName: ApplicationDisplayName = {
    'afcd': 'AFCD Users',
    'auctionportal': 'Auction Portal Users',
    'super-admin': 'Superadmin (NEO) Users'
  }

  isAccountSigner = false;
  isDealerList = true;
  //this checks if string only contains numbers, *, and ,
  regex = /^([\d*]+,)*[\d*]+/
  base64String: string = '';
  documentId: string = '';

  constructor(
    public userService: UserService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private readonly spinner: NgxSpinnerService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly documentUploadService: DocumentUploadService,
  ) {
    this.userForm = this.formBuilder.group({
      appId: ['', Validators.required],
      permission: [{value: '', disabled: this.applicationRoles.length < 1}, {validators: Validators.required}],
      operation: ['', Validators.required],
      updateAll: [false],
      dealerList: ['', [Validators.required, Validators.pattern(this.regex)]]
    })
  }

  ngOnInit(): void {
    this.canBulkUpdate();
    this.getApplication();
  }

  toggleUploadField(){
    const control = this.userForm.get('permission');
    if(control?.value === 'account-signer'){
      this.isAccountSigner = true;
      this.userForm.removeControl('dealerList');
      this.userForm.removeControl('updateAll');
      this.addFormControl('documentId', true);
    }else{
      this.isAccountSigner = false;
      this.isDealerList = true;
      this.userForm.removeControl('documentId');
      this.addFormControl('dealerList', true);
      this.addFormControl('updateAll');
    }
  }

  addFormControl(addControlName: string, isRequired: boolean = false){
    const control = new FormControl('');
    this.userForm.addControl(addControlName, control);
    if(isRequired){
      this.userForm.get(addControlName)?.setValidators([Validators.required]);
    }
    this.userForm.get(addControlName)?.updateValueAndValidity();
    this.userForm.updateValueAndValidity();
  }

  toggleDealeList(){
    const checkboxVal = this.userForm.get('updateAll')?.value;
    if(!checkboxVal){
      this.isDealerList = true;
      this.addFormControl('dealerList', true);
    }else{
      this.isDealerList = false;
      this.userForm.removeControl('dealerList');
      this.userForm.updateValueAndValidity();
    }
  }

  canBulkUpdate() {
    this.canBulkUpdate$ = this.userService.ready$.pipe(
      map(ready => {
        if (ready) {
          return this.userService.hasTopLevelPermission('update-user');
        }
        return false;
      })
    )

    this.canBulkUpdate$.subscribe(permission => {
      //if userService is not ready this will now redirect to home directly.
      if (permission === false) {
        this.router.navigate(['/home']);
      }
    })
  }

  getApplication() {
    this.spinner.show();
    this.userService
      .getApplications()
      .subscribe((data: any) => {
        this.updateApplicationName(data.applications);
        this.spinner.hide();
      });
  }

  updateApplicationName(applications: string[] = []) {
    for (const appName of applications) {
      if (appName in this.applicationDisplayName) {
        this.applications.push({
          name: (this.applicationDisplayName as any)[appName],
          value: appName
        })
      }
    }
  }

  getApplicationRoles() {
    this.spinner.show();
    this.userService
      .getApplicationRoles(this.userForm.get('appId')?.value)
      .subscribe((data: any) => {
        this.applicationRoles = data.roles.map((role: string) => {
          return {
            name: role.split('-').join(' '),
            value: role
          }
        })
        if(this.applicationRoles.length){
          this.userForm.get('permission')?.enable();
        }
        this.spinner.hide();
      });
  }

  uploadCSV_File(event: any){
    const target  = event.target as HTMLInputElement;
    if(target.files && target.files.length > 0){
      this.uploadPresignedUrl(target.files[0]);
    }
  }

  public async uploadPresignedUrl(file: File) {
    this.spinner.show();
    this.base64String = await this.convertToBase64(file);
    const fileType = 'csv';
    if(file.type === "text/csv"){
      const fileName = `${uuidv4()}.csv`;
      this.documentId = fileName;
      this.documentUploadService.getTempDocUrl(fileName).subscribe({
        next: (value: {filename: string, presignedURL: string}) => {
          if(value.presignedURL){
            this.documentUploadService.putDocumentToPresignedUrl(value['presignedURL'], fileType, this.base64String)
            .subscribe({
              next:() => {
                this.spinner.hide();
              }, error: error => {
                this.spinner.hide();
                this.showUploadError(error);
              }
            });
          }
        },
        error: error => {
          this.showUploadError(error);
          this.spinner.hide();
        }
      })
      
    } else{
      this.showUploadError('Wrong file selection');
      this.userForm.get('documentId')?.reset();
      this.userForm.updateValueAndValidity();
    }
  }

  convertToBase64(file: File): Promise<string> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        resolve(e.target.result);
      };
      reader.readAsDataURL(file);
    })
    
  }

  private showUploadError(error: any) {
    this.toastr.error(`There is a problem in uploading CSV file: ${error}`);
  }

  submitBulkUpdateRequest() {
    this.spinner.show();
    let payload = this.userForm.value;
    if(!payload.dealerList){
      payload.documentId = this.documentId;
      delete payload.dealerList;
    }
    if (!payload.updateAll && !payload.documentId) {
      payload.dealerList = this.userForm.get("dealerList")?.value.split(",");
      delete payload.updateAll;
    } else {
      delete payload.dealerList;
    }

    this.userService.submitBulkUpdatePermissionRequest(payload)
      .subscribe({
        next: (data) => {
          this.Requests.push(data);
          this.toastr.success(data.message);
          this.spinner.hide();
        },
        error: (err) => {
          this.toastr.error(err.error);
          this.spinner.hide();
        }
      });
  }
}